:root {
  --primary-icon-color: #52aef4;
  --primary-color: #337ab7;
  --info-color: #5bc0de;
  --warning-color: #f0ad4e;
  --danger-color: #d9534f;
  --success-color: #5cb85c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.lab-field-kits {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}

.label {
  padding: 4px;
  background-color: gray;
  border-radius: 10px;
  word-wrap: keep-all;
  width: max-content;
}

.text-white {
  color: white !important;
}

.icon-color {
  color: var(--primary-icon-color) !important;
}

.text-default {
  color: gray !important;
}

.text-danger {
  color: var(--danger-color) !important;
}

.text-warning {
  color: var(--warning-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.bg-default {
  background-color: gray !important;
}

.bg-danger {
  background-color: var(--danger-color) !important;
}

.bg-warning {
  background-color: var(--warning-color) !important;
}

.bg-success {
  background-color: var(--success-color) !important;
}

.btn {
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
}

.btn-success {
  background-color: var(--success-color);
}

.btn-danger {
  background-color: var(--danger-color);
}

.btn-warning {
  background-color: var(--warning-color);
}

.btn-default {
}

.btn-primary {
  background-color: var(--primary-color);
}

.kit-actions {
  display: flex;
  flex-flow: column;
  row-gap: 5px;
}

.kit-actions > div {
  display: flex;
  flex-flow: column;
  row-gap: 5px;
}

.notification-icons > span > * {
  margin-right: 10px;
}

.details-page-layout .ra-field span {
  font-size: 1rem;
}

.detail_table tbody tr td {
  padding: 8px;
  border: 1px solid black;
}

.form-layout .MuiFormControl-root {
  width: 50% !important;
}

.info-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.info-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.info-table th {
  padding: 12px 15px;
  font-size: 120%;
  font-weight: 700;
}

.info-table td {
  padding: 12px 15px;
}

.info-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.info-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.info-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.info-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.version-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0ad4e;
  padding: 4px 0;
  font-weight: bold;
}

.RaSidebar-fixed {
  width: inherit;
}

textarea {
  height: 80px !important;
}

/* ToolTip */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 300px;
  width: 100%;
  line-height: 1.5;
  background-color: #d6f0f9;
  border: 1px solid #337ab7;
  color: black;
  font-family: sans-serif;
  padding: 5px;
  white-space: pre-line;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;

  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* ToolTip */
.tooltipprovider {
  position: relative;
  display: inline-block;
}

/* Provider ToolTip styling */
.tooltipprovider .tooltipprovidertext {
  visibility: hidden;
  min-width: 300px;
  width: 100%;
  line-height: 1.5;
  background-color: rgb(255, 220, 226);
  border: 1px solid #ff9b9b;
  color: black;
  font-family: sans-serif;
  padding: 5px;
  white-space: pre-line;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;

  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipprovider:hover .tooltipprovidertext {
  visibility: visible;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
}

.test-modal-container {
  width: 100%;
  height: 100%;
  max-width: 80vw;
  max-height: 90vh;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
}

.test-record-form {
  grid-template-columns: 1fr 1fr;
  padding: 0 24px;
}

.test-record-form > div {
  display: flex;
  flex-flow: column;
  row-gap: 16px;
}

.radio-group {
  flex-direction: row !important;
}

.video-container {
  margin: 5px;
  column-gap: 16px;
}

.video-container > div {
  display: flex;
  flex-flow: column;
}

#camera-video {
  border: 1px solid black;
}

.img-capture-section {
  width: 100%;
}

.img-thumb {
  cursor: zoom-in;
  border: solid green 4px;
}

.img-thumb-patientidcard {
  cursor: zoom-in;
  border: solid red 4px;
}

.patientidcard {
  border: solid red 4px;
}

.image-container {
  /* display: flex;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center; */
  width: 100%;
  white-space: nowrap;
  overflow: scroll;
}

.image-container > div {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: fit-content;
}

.image-container .img-thumb-actions {
  display: flex;
  justify-content: space-between;
  color: red;
  display: flex;
  column-gap: 8px;
  margin: 0 8px;
}

.img-thumb-actions label {
  color: #000;
  cursor: pointer;
  margin-left: 8px;
}

.img-thumb-actions input {
  cursor: pointer;
}

.image-container img {
  margin: 0 0.5rem;
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.image-preview-container {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image-preview-container.active {
  display: flex;
}

.image-preview-container > div {
  position: relative;
  display: inline-block;
  width: 85%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.otherProvider {
  color: firebrick;
}

.weeksSinceVas {
  padding-left: 4px;
  color: red;
}

/* Carousel Styles */
.carousel {
  overflow: hidden;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  aspect-ratio: 720 / 480; /* Maintain aspect ratio */
}

.carousel-container {
  display: flex;
  transition: transform 0.3s ease;
  user-select: none; /* Prevent text selection during drag */
}

.carousel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
}

.carousel-wrapper > p {
  font-size: 16px;
  align-self: start;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.carousel-item {
  flex: 0 0 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Prevent cropping or zooming */
  display: block;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.carousel-arrow:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/* Carousel Styles end */
